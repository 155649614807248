import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import RikiTiki from './pages/RikiTiki';
import Shopping from './pages/Shopping';
import { ShopProvider } from './contexts/ShopContext';

function App() {
  return (
    <Router>
      <ShopProvider>
        <Routes>
          <Route path="/shopping" element={<Shopping />} />
          <Route path="/riki-tiki" element={<RikiTiki />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </ShopProvider>
    </Router>
  );
}

export default App;
