import React, { useEffect } from 'react';
import styles from '../styles/Home/Home.module.scss';

function loadScript(src: string, id: string) {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  script.id = id;
  document.body.appendChild(script);
  return script;
}

const Home: React.FC = () => {
  useEffect(() => {
    const scriptId = 'linkedin-badge-script';

    if (document.getElementById(scriptId)) {
      return;
    }

    const script = loadScript(
      'https://platform.linkedin.com/badges/js/profile.js',
      scriptId
    );

    return () => {
      script.remove();
    };
  }, []);
  const LinkedInBadge = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <div
              class="badge-base LI-profile-badge"
              data-locale="en_US"
              data-size="medium"
              data-theme="light"
              data-type="VERTICAL"
              data-vanity="kiss-martin"
              data-version="v1">
            <a
                class="badge-base__link LI-simple-link"
                href="https://hu.linkedin.com/in/kiss-martin?trk=profile-badge">
            </a>
            </div>
            `
        }}
      />
    );
  };

  return (
    <main className={styles.main}>
      <div className={styles.homePage}>
        <div className={styles.bodyBorder}></div>
        <div className={styles.bodyContent}>
          <div className={styles.detailsWrapper}>
            <table className={styles.detailsTable}>
              <tbody>
                <tr>
                  <td className={styles.first}>#name</td>
                  <td className={styles.second}></td>
                </tr>
                <tr>
                  <td className={styles.first2}></td>
                  <td className={styles.second}>:Martin Kiss</td>
                </tr>
                <tr>
                  <td className={styles.first}>#profession</td>
                  <td className={styles.second}></td>
                </tr>
                <tr>
                  <td className={styles.first}></td>
                  <td className={styles.second}>:Frontend Developer</td>
                </tr>
                <tr>
                  <td className={styles.first}>#link</td>
                  <td className={styles.second}></td>
                </tr>
                <tr>
                  <td className={styles.first}></td>
                  <td className={styles.second}>
                    :
                    <a
                      href="https://www.linkedin.com/in/kiss-martin/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Kiss Martin
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className={styles.first}>#description</td>
                  <td className={styles.second}></td>
                </tr>
                <tr>
                  <td className={styles.first}></td>
                  <td className={styles.second}>
                    :Ahogy a mondás tartja, a cipész cipője lyukas a szabó
                    nadrágja feslett, így az én oldalam is fejlesztés alatt áll.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.backgroundWrapper}>
            <div className={styles.backgroundFirst}></div>
            <div className={styles.backgroundSecond}></div>
            <div className={styles.backgroundThird}></div>
            <div className={styles.backgroundFourth}></div>
            <div className={styles.badgeContainer}>
              <div className={styles.badgeWrapper}>{LinkedInBadge()}</div>
            </div>
          </div>
        </div>
        <div className={styles.bodyBorder}></div>
      </div>
    </main>
  );
};

export default Home;
