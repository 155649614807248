import React from 'react';
import styles from '../styles/RikiTiki/RikiTiki.module.scss';

const RikiTiki: React.FC = () => {
  return (
    <div className={styles.rikiTiki}>
      <h1>Welcome to the Riki-Tiki Page</h1>
      <p>This page is dedicated to Riki-Tiki.</p>
    </div>
  );
};

export default RikiTiki;
