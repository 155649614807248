// src/contexts/ShopContext.tsx
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ShopContextType {
  items: number;
  setItems: React.Dispatch<React.SetStateAction<number>>;
}

const ShopContext = createContext<ShopContextType | undefined>(undefined);

export const ShopProvider = ({ children }: { children: ReactNode }) => {
  const [items, setItems] = useState(0);

  return (
    <ShopContext.Provider value={{ items, setItems }}>
      {children}
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error('useShop must be used within a ShopProvider');
  }
  return context;
};
