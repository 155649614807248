import React from 'react';
import { useShop } from '../contexts/ShopContext';
import styles from '../styles/Shopping/Shopping.module.scss';

const Shopping: React.FC = () => {
  const { items, setItems } = useShop();

  return (
    <div className={styles.shopping}>
      <h1>Welcome to the Shopping Page</h1>
      <p>You currently have {items} items in your cart.</p>
      <button onClick={() => setItems(items + 1)}>Add Item</button>
    </div>
  );
};

export default Shopping;
